import React from "react"
import { Link } from "gatsby"
import CookieConsent from "react-cookie-consent"
import Img from "gatsby-image"

import "./recentposts"
import "../styles/global.css"

import logo from "../../content/assets/logo.svg"

function HeaderLink(props){
  return(
    <Link
      to={props.to}
      className="ml-1 mr-3 cursor-pointer text-sm md:text-lg hover:underline"
    >
      {props.label}
    </Link>
  )
}

function FooterLink(props){
  return (
    <Link
      to={props.to}
      className="text-black no-underline hover:underline ml-2 mr-1"
    >
      {props.label}
    </Link>
  )
}

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`

    return (
      <div>
        <header className="w-full bg-white border-b bg-gray-100">
          <div className="container mx-auto max-w-5xl flex block py-4 justify-between items-center">
            <a href="/" className="text-center md:text-left md:text-lg font-semibold no-underline flex justify-center items-center pl-3">
              <div className="w-24 md:w-32"><img src={logo} alt="Logo" /></div>
            </a>
            <nav>
              <HeaderLink
                to="/about"
                label="About Us"
              />
              <HeaderLink
                to="/articles"
                label="Articles"
              />
              <HeaderLink
                to="/membership"
                label="Membership"
              />
            </nav>
          </div>
        </header>

        <div className="p-2 min-h-screen"><main>{children}</main></div>

        <CookieConsent
            location="bottom"
            buttonText="Understood"
            expires={150}
            containerClasses="text-sm text-white fixed bg-gray-600 w-full h-16 mx-auto justify-between flex block items-center"
            buttonClasses="m-2 p-1 bg-yellow-200 rounded text-black mr-4 md:mr-8 h-10 w-32 text-xs"
            contentClasses="ml-2 md:ml-4 leading-tight"
            acceptOnScroll={true}
            acceptOnScrollPercentage={20}
            disableStyles={true}
        >
            Cookies are used on this website, your continued use accepts this. <a class="underline" href="/privacy-policy">Privacy Policy</a>
        </CookieConsent>
  
        <footer className="w-full bg-white px-6 border-t">
          <div className="container mx-auto max-w-4xl py-6 flex flex-wrap md:flex-no-wrap justify-between items-center text-sm">
            &copy;2020 Web Content Creators Association. All rights reserved.
            <div className="pt-4 md:p-0 text-center md:text-right text-xs">
              <FooterLink to="/code-of-conduct" label="Code of Conduct"/>
              <FooterLink to="/about" label="About"/>
              <FooterLink to="/privacy-policy" label="Privacy Policy"/>
              <FooterLink to="/contact" label="Contact Us"/>
            </div>
          </div>
        </footer>
      </div>
    )
  }
}

export default Layout
